import React, { useState } from "react";
import { Avatar, Box, Button, MenuList, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ListAltIcon from "@mui/icons-material/ListAlt";
import WcIcon from "@mui/icons-material/Wc";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import StorefrontIcon from "@mui/icons-material/Storefront";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import { useDispatch, useSelector } from "react-redux";
import MyLogo from "../../assets/logo/f-logo.png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PreviewIcon from "@mui/icons-material/Preview";
import SideBarLink from "../sideBarLink";
import { useLocation, useNavigate } from "react-router-dom";
import { resetLoginState } from "../../Features/auth/auth.slicer";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
const SideBar = ({ children }) => {
  const { lightTheme } = useSelector((state) => state.themeSlice);
  const classes = useStyles({ lightTheme });
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(pathname);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMenuItemClick = (tabName) => {
    setActiveTab(tabName);
    navigate(tabName);
  };
  const handleClick = () => {
    dispatch(resetLoginState());
  };
  return (
    <>
      <Box className={classes.containerMain}>
        <Box className={classes.sideNavBar}>
          <Box>
            <Box sx={{ paddingBottom: 5 }}>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pt: 2,
                }}
              >
                <img src={MyLogo} alt="Logo" width="100%" />
              </Box>
            </Box>

            <Box
              sx={{
                backgroundColor: "#ffffff",
                padding: "10px",
                borderRadius: "8px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Avatar />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "#000000", fontWeight: 600 }}
                  >
                    Admin
                  </Typography>
                  <Button
                    variant="p"
                    sx={{
                      color: (theme) => theme.palette.warning.main,
                    }}
                    onClick={handleClick}
                  >
                    Logout
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <MenuList
            sx={{
              display: { xs: "none", md: "block" },
            }}
          >
            <SideBarLink to="/" icon={<PreviewIcon />} heading="Overview" />
            <SideBarLink
              to="/createTask"
              icon={<GridViewRoundedIcon />}
              heading="Create Task"
            />
            <SideBarLink
              to="/viewtask"
              icon={<RemoveRedEyeIcon />}
              heading="View Task"
            />
            <SideBarLink
              to="/manageTask"
              icon={<ManageAccountsIcon />}
              heading="Manage Task"
            />
            <SideBarLink
              to="/partnerManagment"
              icon={<WcIcon />}
              heading="Partner Managment"
            />
            <SideBarLink
              to="/rewardManagment"
              icon={<EmojiEventsIcon />}
              heading="Rewards Managment"
            />
            /
            <SideBarLink
              to="/walletaddress"
              icon={<StorefrontIcon />}
              heading="Wallet Address"
            />
          </MenuList>
        </Box>
        <Box>{children}</Box>
      </Box>
    </>
  );
};

export default SideBar;

const useStyles = makeStyles((theme) => ({
  containerMain: {
    backgroundColor: theme.palette.primary.main,
    display: "grid",
    gap: 30,
    minHeight: "100vh",
    gridTemplateColumns: "0.5fr 2fr",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  sideNavBar: {
    display: "flex",
    gap: 2,
    alignItems: "left",
    flexDirection: "column",
    paddingLeft: "1em",
    paddingTop: 10,
    "& li": {
      padding: "1em",
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "1em",
    },
  },

  iconss: {
    fontSize: 30,
    color: theme.palette.secondary.main,
  },
}));
