import { createSlice } from "@reduxjs/toolkit";
import { getHostData } from "./houseRequest.action";

const initialState = {
  hostData: [],
  getHostDataLoading: false,
  getHostDataSuccess: false,
  getHostDataFailed: false,
};

const hostSlicer = createSlice({
  name: "host",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getHostData.pending]: state => {
      state.getHostDataLoading = true;
    },
    [getHostData.fulfilled]: (state, action) => {
      state.getHostDataLoading = false;
      state.getHostDataSuccess = true;
      state.hostData = action.payload;
    },
    [getHostData.rejected]: state => {
      state.getHostDataLoading = false;
      state.getHostDataSuccess = false;
      state.getHostDataFailed = false;
    },
  },
});

export default hostSlicer.reducer;
