import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import {
  completedTasks,
  manageUpdateTask,
} from '../../Features/update-manage-task/updateMageTask.action';
import toast, { Toaster } from 'react-hot-toast';
import { Box, CircularProgress } from '@mui/material';

const columns = [
  { id: 'task_name', label: 'Task Name', width: '15%' },
  { id: 'task_completion_link', label: 'Completed Link', width: '50%' },
  { id: 'coins', label: 'Coins', width: '20%' },
];

export default function TaskManagment() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [coinsValues, setCoinsValues] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(completedTasks());
  }, [dispatch]);

  const { userCompletedTask, isUserCompletedTaskLoading, updateTask } =
    useSelector((state) => state.updateTaskSlicer);
  const { lightTheme } = useSelector((state) => state.themeSlice);

  useEffect(() => {
    if (userCompletedTask) {
      // Initialize coinsValues with 1000 for all tasks
      const initialValues = userCompletedTask.reduce((acc, task) => {
        acc[task.task_manage_id] = 1000; // Set default value to 1000
        return acc;
      }, {});
      setCoinsValues(initialValues);
    }
  }, [userCompletedTask]);

  const handleCoinsChange = (taskId, value) => {
    setCoinsValues((prevValues) => ({
      ...prevValues,
      [taskId]: Number(value),
    }));
  };

  const handleSubmitAll = () => {
    const tasksData = userCompletedTask.map((task) => ({
      task_manage_id: task.task_manage_id,
      user_id: task.user_id,
      task_reward: coinsValues[task.task_manage_id], // Updated value or default 1000
    }));

    setLoading(true);

    dispatch(manageUpdateTask(tasksData)).then(() => {
      toast.success(updateTask?.message);
      dispatch(completedTasks());
      setLoading(false);
    });
  };

  return (
    <>
      <Toaster position="bottom-right" reverseOrder={false} />
      {isUserCompletedTaskLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="secondary" size={80} />
        </Box>
      ) : (
        <Paper sx={{ width: '80%', overflow: 'hidden', margin: '0 auto' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      sx={{
                        backgroundColor:
                          lightTheme === false ? '#161616' : 'default',
                        fontWeight: 'bold',
                        width: column.width,
                      }}
                      key={column.id}
                      align={column.align}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userCompletedTask &&
                  userCompletedTask
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.id === 'coins') {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <TextField
                                  id={`coins-${row.id}`}
                                  label="Coins"
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  value={
                                    coinsValues[row.task_manage_id] !==
                                    undefined
                                      ? coinsValues[row.task_manage_id]
                                      : 1000
                                  }
                                  onChange={(e) =>
                                    handleCoinsChange(
                                      row.task_manage_id,
                                      e.target.value
                                    )
                                  }
                                  InputLabelProps={{
                                    sx: {
                                      color:
                                        lightTheme === false
                                          ? 'white'
                                          : 'inherit',
                                    },
                                  }}
                                  sx={{
                                    marginRight: '10px',
                                  }}
                                />
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '16px',
                              }}
                            >
                              {column.id === 'task_completion_link' ? (
                                <a
                                  href={value}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {value}
                                </a>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[6, 12, 36]}
            component="div"
            count={userCompletedTask.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Box
            sx={{
              textAlign: 'center',
              margin: '20px 0',
              display: `${userCompletedTask.length === 0 ? 'none' : 'block'}`,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitAll}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="secondary" />
              ) : (
                'Submit All'
              )}
            </Button>
          </Box>
        </Paper>
      )}
    </>
  );
}
