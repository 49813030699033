import { useEffect } from "react"; // <-- Add this import
import { useDispatch } from "react-redux";
import { checkSession } from "../../Features/auth/auth.action";

export const useCheckSession = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkSession());
  }, [dispatch]);

  return null;
};
