import { Box, MenuItem, Typography, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { NavLink } from "react-router-dom";

const SideBarLink = ({ icon, to, heading, isMobileView }) => {
  const classes = useStyles();
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        clsx(classes.link, { [classes.activeLink]: isActive && !isMobileView })
      }
    >
      <Box sx={{ padding: "2.5px 0" }}>
        <MenuItem value={to}>
          {icon}
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, fontSize: 17, textDecoration: "none" }}
          >
            {heading}
          </Typography>
        </MenuItem>
      </Box>
    </NavLink>
  );
};

export default SideBarLink;

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    "& li": { gap: "0.5em", borderRadius: 10 },
    "&:hover": {
      "& li": {
        backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      },
    },
  },
  activeLink: {
    "& li": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      borderWidth: "0px 4px",
      borderColor: theme.palette.secondary.main,
      borderStyle: "solid",
      borderRadius: 10,
      transition: ".25s",
    },
  },
}));
