import { makeStyles } from "@mui/styles";
import React from "react";
import { Typography } from "@mui/material";

export default function TaskManagment() {
  return (
    <div>
      <Typography>Task Managment</Typography>
    </div>
  );
}
