import React, { useEffect } from "react";

import BasicTable from "../table";

import Button from "../units/Button";
import { Box, Divider, TableCell, TableRow, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import image from "../../assets/image3.png";
import TablePagination from "../Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getHostData } from "../../Features/houseRequest/houseRequest.action";
const columns = ["Email", "Contact", " Address", "Image", "actions"];

const WalletAddress = ({ viewAllBtn = true }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.hostSlicer.hostData);
  console.log(data);
  const host = [" a", "b", "c"];
  const rows = [host, host, host, host];
  const head = columns.map((col, id) => (
    <TableCell key={id} className={classes.col}>
      {col}
    </TableCell>
  ));
  <Divider />;
  const body = data?.map(({ id, email, number, address }) => (
    <TableRow key={id}>
      <TableCell className={classes.col}>{email}</TableCell>
      <TableCell className={classes.col}>{number}</TableCell>

      <TableCell className={classes.col}>
        <Typography>{address}</Typography>
      </TableCell>

      <TableCell className={classes.col}>
        <img src={image} alt={image} className={classes.productImage} />
      </TableCell>
      <TableCell className={classes.btnAction}>
        <Button variant="outlined">Delete</Button>
      </TableCell>
    </TableRow>
  ));
  useEffect(() => {
    dispatch(getHostData());
  }, [dispatch]);
  return (
    <>
      <BasicTable
        tableName="All Available Hosts"
        head={head}
        body={body}
        path="/"
      >
        <Box className={classes.buttonViewAllbox}>
          <TablePagination props={rows} />
        </Box>
      </BasicTable>
    </>
  );
};
export default WalletAddress;

const useStyles = makeStyles((theme) => ({
  col: {
    color: theme.palette.text.primary + "!important",
  },

  buttonViewAllbox: {
    display: "flex",
    justifyContent: "center",
  },
  buttonViewAll: {
    width: 130,
    height: 30,
  },
  btnview: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  productImage: {
    width: 60,
    height: 60,
    borderRadius: 5,
  },

  btnAction: {
    alignItems: "center",
  },
}));
