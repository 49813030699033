import { createSlice } from "@reduxjs/toolkit";
import { adminLogin, verifyOtp } from "./auth.action";

const initialState = {
  isLoginSuccess: false,
  isLoginLoading: false,
  isLoginFailed: false,
  login: {},
  error: {},
  isVerifyOtpSuccess: false,
  isVerifyOtpLoading: false,
  isVerifyOtpFailed: false,
};

const authSlicer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetLoginState: (state) => {
      state.isVerifyOtpLoading = false;
      state.isVerifyOtpSuccess = false;
      state.isVerifyOtpFailed = false;
      state.isLoginLoading = false;
      state.isLoginSuccess = false;
      state.isLoginFailed = false;
      localStorage.removeItem("authToken");
      state.login = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminLogin.pending, (state) => {
      state.isLoginLoading = true;
    });
    builder.addCase(adminLogin.fulfilled, (state, action) => {
      state.isLoginLoading = false;
      state.isLoginSuccess = true;
      state.login = action.payload;
    });
    builder.addCase(adminLogin.rejected, (state, action) => {
      state.isLoginLoading = false;
      state.isLoginSuccess = false;
      state.isLoginFailed = true;
      state.error = action.payload;
    });

    // Verify OTP cases
    builder.addCase(verifyOtp.pending, (state) => {
      state.isVerifyOtpLoading = true;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.isVerifyOtpLoading = false;
      state.isVerifyOtpFailed = false;
      state.isVerifyOtpSuccess = true;
      state.login = action.payload;
      localStorage.setItem("authToken", action.payload.token);
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      state.isVerifyOtpLoading = false;
      state.isVerifyOtpSuccess = false;
      state.isVerifyOtpFailed = true;
      state.error = action.payload.message;
    });
  },
});

export default authSlicer.reducer;
export const { resetLoginState } = authSlicer.actions;
