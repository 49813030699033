import {
  Box,
  Button,
  CircularProgress,
  InputBase,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import logo from '../../assets/logo/favicon.ico';
import { useFormik } from 'formik';
import VerificationCodeInput from '../../components/verification';
import { useStyles } from './style';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { adminLogin, verifyOtp } from '../../Features/auth/auth.action';
import { Toaster } from 'react-hot-toast';
import toast from 'react-hot-toast';
import { resetLoginState } from '../../Features/auth/auth.slicer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { resetLoginState } from "../../Features/auth/auth.slicer";
const Login = () => {
  const auth = useSelector((state) => state.auth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState();
  const [otpMail, setOtpMail] = useState('');
  const [back, setBack] = useState(true);

  const {
    isLoginSuccess,
    isLoginLoading,
    isVerifyOtpSuccess,
    isVerifyOtpFailed,
    isLoginFailed,
    error,
    login,
  } = useSelector((state) => state.auth);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setBack(true);
      dispatch(resetLoginState());
      dispatch(adminLogin(values));
      setOtpMail(values.email);
      resetForm();
    },
  });

  const handleClick = () => {
    let payload = {
      email: otpMail,
      otp: otp,
    };
    dispatch(verifyOtp(payload));
  };
  useEffect(() => {
    if (isLoginFailed) {
      toast.error(error.message);
    }
    if (login.message) {
      toast.success('OTP Sent successfully');
    }
    if (isVerifyOtpSuccess) {
      toast.success('Admin Login successfull');
      setTimeout(() => {
        navigate('/', { replace: true }); // Redirect to home page after successful login
      }, 2000);
    }
    if (isVerifyOtpFailed) {
      toast.error('You Enter Invalid OTP');
    }
  }, [
    isVerifyOtpSuccess,
    isLoginSuccess,
    isVerifyOtpFailed,
    isLoginFailed,
    dispatch,
    navigate,
  ]);
  /////////////////////
  const handleVerificationBack = () => {
    setBack(false);
    dispatch(resetLoginState());
  };
  return (
    <>
      <Toaster position="bottom-right" reverseOrder={false} />
      <Box className={classes.loginFirstBox}>
        <form onSubmit={formik.handleSubmit} className={classes.formStyle}>
          {isLoginSuccess && back ? (
            <ArrowBackIcon
              sx={{ color: 'white' }}
              onClick={handleVerificationBack}
            />
          ) : null}
          <Box className={classes.logoBox}>
            <img src={logo} alt="website-logo" width="55vw" />
          </Box>
          {isLoginSuccess && back ? (
            <VerificationCodeInput setOtp={setOtp} />
          ) : (
            <Box>
              <Box className={classes.typoContent}>
                <Typography className={classes.typography}>Email</Typography>
                <InputBase
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeholder="Enter your email..."
                  className={classes.inputStyle}
                />
                {formik.touched.email && formik.errors.email && (
                  <Typography className={classes.errorText}>
                    {formik.errors.email}
                  </Typography>
                )}

                <Typography className={classes.typography}>Password</Typography>
                <InputBase
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  placeholder="password"
                  className={classes.inputStyle}
                />
                {formik.touched.password && formik.errors.password && (
                  <Typography className={classes.errorText}>
                    {formik.errors.password}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          {isLoginSuccess && back ? (
            <Button onClick={handleClick} className={classes.buttonStyle}>
              Submit
            </Button>
          ) : (
            <Button type="submit" className={classes.buttonStyle}>
              {isLoginLoading ? (
                <CircularProgress color="inherit" size={30} />
              ) : (
                'Log In'
              )}
            </Button>
          )}
        </form>
      </Box>
    </>
  );
};

export default Login;
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long'),
});
