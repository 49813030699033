import React from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

export default function BasicTable({
  tableName = null,
  head = null,
  body = null,
  addBtn = false,
  addEport = false,
  loading = false,
  path,
  children,
  exportBtn = false,
  handleClickExport = () => null,
  handleExport,
  exportLink,
}) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.main} component={Paper} elevation={0}>
      <Box className={classes.tableHeading}>
        {tableName}
        {/* {addBtn && (
          <>
            <Button
              onClick={handleClick}
              variant="outlined"
              color="primary"
              className={classes.addBtnTitleBar}
            >
              Add
            </Button>
          </>
        )} */}
      </Box>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className="tablehead">
          <TableRow className={classes.textrow}>{head}</TableRow>
        </TableHead>

        <TableBody className={classes.textcol}>{body}</TableBody>
      </Table>
      <Box pt={1} pb={2}>
        {children}
      </Box>
    </TableContainer>
  );
}
const useStyles = makeStyles((theme) => ({
  main: {
    borderRadius: "8px",
    // marginBottom: "50px",
    "&::-webkit-scrollbar": {
      width: 8,
      height: 12,
    },
    /* Track */
    "&::-webkit-scrollbar-track": {
      background: theme.palette.secondary.main + "!important",
      height: 5,
      border: " 1px solid #E6E6E6",
      borderRadius: 50,
    },
    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#C4C4C4",
      borderRadius: 50,
      border: "1px solid var(--black)",
      /* Handle on hover */
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#777878",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "655px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "450px",
    },
  },
  table: {
    minWidth: 650,
    [theme.breakpoints.down("md")]: {
      scrollDirection: "horizontal",
      // scrollDehavior: "smooth",
    },
    "& .tablehead": {
      display: "flex",
      justifyContent: "start",
    },
  },
  textrow: {
    frontWidth: 500,
    fonrSize: 15,
    textTransform: "capitalize",
    color: theme.palette.text.primary + "!important",
  },
  textcol: {
    frontWidth: 400,
    color: theme.palette.text.primary + "!important",
  },
  tableHeading: {
    color: theme.palette.text.primary + "!important",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 15px",

    textTransform: "uppercase",
    fontSize: 15,
    fontWeight: 600,
  },
  addBtnTitleBar: {
    color: "white",
    backgroundColor: "#0660FE",
    border: "1px solid #0660FE",
    padding: "5px 10px",
    borderRadius: "27px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#0660FE",
    },
  },
}));
