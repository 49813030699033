import { createSlice } from "@reduxjs/toolkit";
import { createTasks } from "./createTask.actions";
const initialState = {
  isCreateLoading: false,
  isCreateFailed: false,
  isCreateSuccess: false,
  taskCreate: [],
  error: {},
};
const taskCreate = createSlice({
  name: "rewards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createTasks.pending, (state) => {
      state.isCreateLoading = true;
    });
    builder.addCase(createTasks.fulfilled, (state, action) => {
      state.isCreateLoading = false;
      state.isCreateSuccess = true;
      state.taskCreate = action.payload;
    });
    builder.addCase(createTasks.rejected, (state, action) => {
      state.isCreateLoading = false;
      state.isCreateSuccess = false;
      state.isCreateFailed = true;
      state.error = action.payload;
    });
  },
});
export default taskCreate.reducer;
