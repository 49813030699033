import { createTheme } from "@mui/material/styles";

export const darkThemee = createTheme({
  palette: {
    primary: {
      main: "#3d3d3d",
    },
    secondary: {
      main: "#FFFFFF",
    },
    info: {
      main: "#25C2E3",
    },
    background: {
      text: "#000",
      btnback: "#000",
      contact: "#1B1B1B",
      login: "#000000",
      loginsection: "#0E0E0E",
      accountRed: "#D34B56",
      paper: "#171717",
    },
    input: {
      main: "#8D8D8D",
    },
    btn: {
      border: "#FFFFFF",
      color: "#FFFFFF",
    },
    text: {
      primary: "#fff",
    },
    divider: "#C6D0D4",
  },
  typography: {
    fontFamily: " Urbanist, sans-serif",
  },
});
