import React from "react";
import { Box, Typography } from "@mui/material";
// import SearchBar from "../search";
import { useDispatch, useSelector } from "react-redux";
import imagemoon from "../../assets/imagemoon.png";
import { toogleTheme } from "../../redux/theme.slicer";
import imageSun from "../../assets/imageSun.png";
export default function Header({ heading }) {
  const dispatch = useDispatch();
  const { lightTheme } = useSelector((state) => state.themeSlice);
  const handleToogleTheme = () => {
    dispatch(toogleTheme(!lightTheme));
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        gap: { lg: 3.75, md: 1.5 },
      }}
    >
      <Typography variant="h5" color="secondary">
        {heading}
      </Typography>
      {/* <SearchBar /> */}
      <Box>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.grey[400],
            borderRadius: "30px",
            display: "flex",
            padding: "2px 4px",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            minWidth: "40px",
            maxWidth: "40px",
            flexDirection: lightTheme ? "row" : "row-reverse",
          }}
          onClick={handleToogleTheme}
        >
          <Box
            sx={{
              display: "flex",
              backgroundColor: "black",
              borderRadius: "50%",
              width: "15px",
              height: "15px",
            }}
          ></Box>
          <img src={lightTheme ? imagemoon : imageSun} alt="iconTheme" />
        </Box>
      </Box>
    </Box>
  );
}
