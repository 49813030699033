import { createSlice } from "@reduxjs/toolkit";
import { overViewStates } from "./overview.action";

const initialState = {
  isOverviewLoading: false,
  isOverviewFailed: false,
  isOverviewSuccess: false,
  states: {},
};

const overViewSlice = createSlice({
  name: "overviewStates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(overViewStates.pending, (state) => {
      state.isOverviewLoading = true;
    });
    builder.addCase(overViewStates.fulfilled, (state, action) => {
      state.isOverviewLoading = false;
      state.isOverviewSuccess = true;
      state.states = action.payload;
    });

    builder.addCase(overViewStates.rejected, (state) => {
      state.isOverviewLoading = false;
      state.isOverviewSuccess = false;
      state.isOverviewFailed = true;
    });
  },
});

export default overViewSlice.reducer;
