import { Paper, Typography } from '@mui/material';
import { getRewards } from '../../Features/rewardsInfo/rewardsInfo.action';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const DashboardCard = ({ heading, amount }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRewards());
  }, [dispatch]);

  return (
    <Paper
      sx={{
        padding: 3,
        borderRadius: 2,
      }}
      elevation={0}
    >
      <Typography variant="h6" align="center">
        {heading}
      </Typography>
      <Typography variant="h5" align="center">
        {amount}
      </Typography>
    </Paper>
  );
};

export default DashboardCard;
