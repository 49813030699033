import { createSlice } from "@reduxjs/toolkit";
import { updatePartnerLinks } from "./partnerMng.action";

const initialState = {
  isRewardsLoading: false,
  isRewardsFailed: false,
  isRewardsSuccess: false,
  updatePartners: [],
  error: {},
};
const updatePartnerLink = createSlice({
  name: "rewards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updatePartnerLinks.pending, (state) => {
      state.isRewardsLoading = true;
    });
    builder.addCase(updatePartnerLinks.fulfilled, (state, action) => {
      state.isRewardsLoading = false;
      state.isRewardsSuccess = true;
      state.updatePartners = action.payload;
    });
    builder.addCase(updatePartnerLinks.rejected, (state, action) => {
      state.isRewardsLoading = false;
      state.isRewardsSuccess = false;
      state.isRewardsFailed = true;
      state.error = action.payload;
    });
  },
});
export default updatePartnerLink.reducer;
