import { TAPTAP } from "../../http/config";

export const viewTask = async (payload) => {
  try {
    const res = await TAPTAP.get("task", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
