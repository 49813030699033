import { createSlice } from '@reduxjs/toolkit';
import { getRewards, updateRewards } from './rewardsInfo.action';
const initialState = {
  isRewardsLoading: false,
  isRewardsFailed: false,
  isRewardsSuccess: false,
  rewardsInfo: [],
  //   Update Coins
  isUpdateRewardsLoading: false,
  isUpdateRewardsFailed: false,
  isUpdateRewardsSuccess: false,
  success: {},
  error: {},
};
const RewardInfo = createSlice({
  name: 'rewards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRewards.pending, (state) => {
      state.isRewardsLoading = true;
    });

    builder.addCase(getRewards.fulfilled, (state, action) => {
      state.isRewardsLoading = false;
      state.isRewardsFailed = false;
      state.isRewardsSuccess = true;
      state.rewardsInfo = action.payload;
    });
    builder.addCase(getRewards.rejected, (state) => {
      state.isRewardsLoading = false;
      state.isRewardsFailed = true;
      state.isRewardsSuccess = false;
    });
    ///////////////////////////////////////////////////////////
    builder.addCase(updateRewards.pending, (state) => {
      state.isUpdateRewardsLoading = true;
      state.isUpdateRewardsSuccess = false;
      state.isUpdateRewardsFailed = false;
    });

    builder.addCase(updateRewards.fulfilled, (state, action) => {
      state.isUpdateRewardsLoading = false;
      state.isUpdateRewardsFailed = false;
      state.isUpdateRewardsSuccess = true;
      state.success = action.payload.message;
    });
    builder.addCase(updateRewards.rejected, (state, action) => {
      state.isUpdateRewardsLoading = false;
      state.isUpdateRewardsSuccess = false;
      state.isUpdateRewardsFailed = true;
      state.error = action.payload.message;
    });
  },
});
export default RewardInfo.reducer;
