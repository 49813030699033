import { TAPTAP } from "../../http/config";

export const getHostDataApi = async (payload) => {
  try {
    const res = await TAPTAP.get("/api/getHosts", payload);
    return res;
  } catch (error) {
    throw error;
  }
};

//

export const getHouseDataApi = async (payload) => {
  try {
    const res = await TAPTAP.get("/api/getHosts", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
