import { createAsyncThunk } from "@reduxjs/toolkit";
import { createTask } from "./api";

export const createTasks = createAsyncThunk(
  "task/createTask",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await createTask(payload);
      return res.data;
    } catch (err) {
      throw err.response.data.message;
    }
  }
);
