import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { darkThemee } from "../theme/darkTheme";
import { lightThemee } from "../theme/lightTheme";
import TaskManagment from "../pages/task-managment";
import { Box } from "@mui/material";
import SideBar from "../components/sideBar";
import Wrapper from "../components/wrapper";
import PartnerManagment from "../components/partner-managment";
import OverView from "../components/overview";
import RewardManagment from "../components/reward-managment";
import WalletAddress from "../components/wallet-address";
import Login from "../pages/Login";
import { useCheckSession } from "../components/checkSession";
import ManageTask from "../pages/manage-task/ManageTask";
import CreateTask from "../pages/create-task/CreateTask";
import ViewTask from "../pages/view-task/ViewTask";
const AuthProtection = ({ children }) => {
  const { isVerifyOtpSuccess } = useSelector((state) => state.auth);

  if (!isVerifyOtpSuccess) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const Routers = () => {
  const { lightTheme } = useSelector((state) => state.themeSlice);
  useCheckSession();

  return (
    <ThemeProvider theme={lightTheme ? lightThemee : darkThemee}>
      <Box sx={{ backgroundColor: (theme) => theme.palette.primary.main }}>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />

            <Route
              path="/"
              element={
                <AuthProtection>
                  <SideBar>
                    <Wrapper pageName="Activity Overview">
                      <OverView />
                    </Wrapper>
                  </SideBar>
                </AuthProtection>
              }
            />

            <Route
              path="/taskmanagment"
              element={
                <AuthProtection>
                  <SideBar>
                    <Wrapper pageName="Task Managment">
                      <TaskManagment />
                    </Wrapper>
                  </SideBar>
                </AuthProtection>
              }
            />
            <Route
              path="/createtask"
              element={
                <AuthProtection>
                  <SideBar>
                    <Wrapper pageName="Create Task">
                      <CreateTask />
                    </Wrapper>
                  </SideBar>
                </AuthProtection>
              }
            />
            <Route
              path="/managetask"
              element={
                <AuthProtection>
                  <SideBar>
                    <Wrapper pageName="Manage Task">
                      <ManageTask />
                    </Wrapper>
                  </SideBar>
                </AuthProtection>
              }
            />

            <Route
              path="/partnerManagment"
              element={
                <AuthProtection>
                  <SideBar>
                    <Wrapper pageName="Partner Managment">
                      <PartnerManagment />
                    </Wrapper>
                  </SideBar>
                </AuthProtection>
              }
            />

            <Route
              path="/rewardManagment"
              element={
                <AuthProtection>
                  <SideBar>
                    <Wrapper pageName="Reward Managment">
                      <RewardManagment />
                    </Wrapper>
                  </SideBar>
                </AuthProtection>
              }
            />

            <Route
              path="/walletaddress"
              element={
                <AuthProtection>
                  <SideBar>
                    <Wrapper pageName=" Wallet Address">
                      <WalletAddress />
                    </Wrapper>
                  </SideBar>
                </AuthProtection>
              }
            />
            <Route
              path="/viewtask"
              element={
                <AuthProtection>
                  <SideBar>
                    <Wrapper pageName=" View Task">
                      <ViewTask />
                    </Wrapper>
                  </SideBar>
                </AuthProtection>
              }
            />
          </Routes>
        </Router>
      </Box>
    </ThemeProvider>
  );
};

export default Routers;
