import { combineReducers } from "@reduxjs/toolkit";
import themeSlice from "./theme.slicer";
import hostSlicer from "../Features/houseRequest/houseRequest.slicer";
import overviewSlicer from "../Features/overveiw/overview.slicer";
import rewardsInfoSlicer from "../Features/rewardsInfo/rewardsInfo.slicer";
import authSlicer from "../Features/auth/auth.slicer";
import updatePartnerLinK from "../Features/partnerManagmet/partnerMng.slicer";
import taskCreate from "../Features/createTask/createTask.slicer";
import taskViews from "../Features/viewTask/viewTask.slicer";
import updateTaskSlicer from "../Features/update-manage-task/updateManageTask.slicer";
const rootReducer = combineReducers({
  themeSlice,
  auth: authSlicer,
  hostSlicer: hostSlicer,
  overview: overviewSlicer,
  rewardsInfoSlicer: rewardsInfoSlicer,
  updateLinks: updatePartnerLinK,
  taskCreate: taskCreate,
  viewTaskSlicer: taskViews,
  updateTaskSlicer: updateTaskSlicer,
});
export default rootReducer;
