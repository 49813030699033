import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardCard from "../dashboardCard";
import { useDispatch, useSelector } from "react-redux";
import { overViewStates as getOverViewStates } from "../../Features/overveiw/overview.action";
const OverView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    states: { totalUsers, dailyUsers, onlineUsers },
  } = useSelector((state) => state.overview);

  useEffect(() => {
    dispatch(getOverViewStates());
  }, [dispatch]);
  return (
    <div className={classes.home}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { lg: "2.5fr 2fr", md: "1fr" },
          gap: 3,
        }}
      >
        <Box pb={1}>
          <Typography variant="h6">Overview</Typography>
        </Box>
      </Box>
      <Box>
        <Box className={classes.salesGrid}>
          <DashboardCard heading="Total Players" amount={totalUsers} />
          <DashboardCard heading="Daily Users" amount={dailyUsers} />
          <DashboardCard heading="Onilne Players" amount={onlineUsers} />
        </Box>
      </Box>
    </div>
  );
};
export default OverView;
const useStyles = makeStyles((theme) => ({
  home: {
    color: theme.palette.secondary.main,
    display: "grid",
    gap: 24,
  },
  activityGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    height: "calc(100% - 40px)",
    gap: 24,
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr ",
    },
  },
  salesGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr ",
    },
    gap: 24,
  },
}));
