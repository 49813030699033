import { TAPTAP } from "../../http/config";

export const getRewardsApi = async () => {
  try {
    const res = await TAPTAP.get("rewardsInfo");
    return res;
  } catch (error) {
    throw error;
  }
};

export const rewardsUpdateApi = async (payload) => {
  try {
    const res = await TAPTAP.put("updatePartnerCoins", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
