import { TAPTAP } from "../../http/config";

export const loginApi = async (payload) => {
  try {
    const res = await TAPTAP.post("adminLogin", payload);
    return res;
  } catch (error) {
    throw error;
  }
};

///////////////////////////////////////////////////////////////////

export const verifyOtpApi = async (payload) => {
  try {
    const res = await TAPTAP.post("verifyOtp", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
/////////////////////////////////////////////////////////////////////////
export const checkSessionApi = async (payload) => {
  try {
    const res = await TAPTAP.get("checkSession", {
      headers: {
        Authorization: payload,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
