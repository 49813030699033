import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOverviweStates } from "./api";

export const overViewStates = createAsyncThunk(
  "overviewStates/stats",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await getOverviweStates(payload);
      return res.data;
    } catch (err) {
      throw err;
    }
  }
);
