import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRewardsApi, rewardsUpdateApi } from './api';
export const getRewards = createAsyncThunk(
  'rewards/getRewards',
  async (payload) => {
    try {
      const res = await getRewardsApi(payload);
      return res.data;
    } catch (err) {
      throw err;
    }
  }
);
///////////////////////////////////////////////////////////
export const updateRewards = createAsyncThunk(
  'rewards/updateRewards',
  async (payload) => {
    try {
      const res = await rewardsUpdateApi(payload);
      return res.data;
    } catch (err) {
      throw err.response.data.message;
    }
  }
);
