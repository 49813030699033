import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputBase,
} from "@mui/material";
import { styled } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { createTasks } from "../../Features/createTask/createTask.actions";
import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";
// Dummy data for platforms
const platforms = [
  { id: 0, label: "Select Platform" },
  { id: 1, label: "YouTube", value: "youtube" },
  { id: 2, label: "Discord", value: "discord" },
  { id: 3, label: "Instagram", value: "instagram" },
  { id: 4, label: "Facebook", value: "facebook" },
  { id: 5, label: "Twitter", value: "twitter" },
  { id: 6, label: "Website", value: "website" },
  { id: 7, label: "Telegram", value: "telegram" },
];

const StyledInput = styled(InputBase)(({ theme }) => ({
  width: "100%",
  padding: "10px",
  border: `1px solid ${theme.palette.text.primary} !important`,
  borderRadius: "4px",
  marginBottom: "16px",
}));

export default function CreateTask() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    isRewardsFailed,
    isRewardsLoading,
    isRewardsSuccess,
    updatePartners,
  } = useSelector((state) => state.updateLinks);
  const formik = useFormik({
    initialValues: {
      social_id: 0,
      task_name: "",
      task_link: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(createTasks(values));

      toast.success("New Task Added Successfully");
      setTimeout(() => {
        resetForm();
      }, 1000);
    },
  });
  // useEffect(() => {
  //   console.log(isRewardsSuccess);
  // });
  const handlePlatformChange = (event) => {
    formik.setFieldValue("social_id", event.target.value);
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Toaster position="bottom-right" reverseOrder={false} />
      <Box>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <FormControl
            fullWidth
            sx={{ mb: 2 }}
            error={formik.touched.social_id && Boolean(formik.errors.social_id)}
          >
            <Select
              name="social_id"
              value={formik.values.social_id}
              onChange={handlePlatformChange}
              displayEmpty
              className={classes.select}
              classes={{ icon: classes.selectIcon }}
            >
              {/* <MenuItem value="" disabled>
                Select Platform
              </MenuItem> */}
              {platforms.map((platform) => (
                <MenuItem key={platform.id} value={platform.id}>
                  {platform.label}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.social_id && formik.errors.social_id && (
              <Typography variant="body2" color="error">
                {formik.errors.social_id}
              </Typography>
            )}
          </FormControl>

          <StyledInput
            id="task_name"
            name="task_name"
            placeholder="Task Name"
            value={formik.values.task_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.task_name && Boolean(formik.errors.task_name)}
          />
          {formik.touched.task_name && formik.errors.task_name && (
            <Typography variant="body2" color="error">
              {formik.errors.task_name}
            </Typography>
          )}

          <StyledInput
            id="task_link"
            name="task_link"
            placeholder="Task Link"
            value={formik.values.task_link}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.task_link && Boolean(formik.errors.task_link)}
          />
          {formik.touched.task_link && formik.errors.task_link && (
            <Typography variant="body2" color="error">
              {formik.errors.task_link}
            </Typography>
          )}

          <Button className={classes.submitButton} type="submit">
            Submit
          </Button>
        </form>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  submitButton: {
    color: `${theme.palette.text.primary} !important`,
    fontWeight: "bold !important",
    border: `1px solid ${theme.palette.text.primary} !important`,
    fontSize: "16px  !important",
    width: "fit-content !important",
    padding: "5px 40px !important",
    alignSelf: "center !important",
    letterSpacing: "1px",
    transition: "transform 500ms ease-linear !important",
    "&:hover": {
      transform: "scale(1.05) !important",
    },
  },
  select: {
    border: `1px solid ${theme.palette.text.primary} !important`,
  },
  form: {
    background: theme.palette.background.text,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "450px",
    padding: "20px",
    borderRadius: "10px ",
  },
  selectIcon: {
    color: `${theme.palette.text.primary} !important`,
  },
}));

// Validation schema
const validationSchema = Yup.object({
  social_id: Yup.number().required("Please select a social_id platform"),
  task_name: Yup.string().required("Please enter tweet text"),
  task_link: Yup.string()
    .url("Invalid URL")
    .required("Please enter tweet link"),
});
