import { createAsyncThunk } from "@reduxjs/toolkit";
import { updatePartner } from "./api";

export const updatePartnerLinks = createAsyncThunk(
  "rewards/rewardsManagment",
  async (payload) => {
    try {
      const res = await updatePartner(payload);
      return res.data;
    } catch (err) {
      throw err.response.message;
    }
  }
);
