import { TAPTAP } from "../../http/config";

export const updatePartner = async (payload) => {
  try {
    const res = await TAPTAP.put("updatePartnerLinks", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
