import React from "react";

import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";

export default function TablePagination(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Pagination {...props} />
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginTop: theme.spacing(2),
    },
    "& .MuiButtonBase-root:hover": {},
    "& .MuiPaginationItem-page.Mui-selected": {
      color: theme.palette.background.text,
      background: theme.palette.text.primary,
    },
    "& .MuiPaginationItem-root": {
      color: theme.palette.text.primary,
    },
  },
}));
