import { TAPTAP } from '../../http/config';

export const updateMngTask = async (payload) => {
  try {
    const res = await TAPTAP.put('rewardCoins', payload);
    return res;
  } catch (error) {
    throw error;
  }
};

// fetch all completed tasks which are not rewarded
export const userCompletedTasks = async (payload) => {
  try {
    const res = await TAPTAP.get('allCompletedTasks', payload);
    return res;
  } catch (error) {
    throw error;
  }
};
