import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useStyles } from "./style";

const VerificationCodeInput = ({ setOtp }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box>
        <Typography fontWeight="bold" color="#d8d4d4">
          Enter your verification code
        </Typography>
      </Box>
      <Box>
        <TextField
          className={classes.textField}
          variant="outlined"
          color="primary"
          size="small"
          focused
          name="otp"
          onChange={(e) => setOtp(e.target.value)}
        />
      </Box>
    </Box>
  );
};

export default VerificationCodeInput;
