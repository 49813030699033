import { createSlice } from '@reduxjs/toolkit';
import { completedTasks, manageUpdateTask } from './updateMageTask.action';
const initialState = {
  isUpdateTaskLoading: false,
  isUpdateTaskSuccess: false,
  isUpdateTaskFailed: false,
  updateTask: {},
  error: {},
  // fetch all completed task
  isUserCompletedTaskLoading: false,
  isUserCompletedTaskSuccess: false,
  isUserCompletedTaskFailed: false,
  userCompletedTask: [],
};
const updateTaskSlicer = createSlice({
  name: 'updateManageTask',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(manageUpdateTask.pending, (state, action) => {
      state.isUpdateTaskLoading = true;
    });
    builder.addCase(manageUpdateTask.fulfilled, (state, action) => {
      state.isUpdateTaskLoading = false;
      state.isUpdateTaskSuccess = true;
      state.updateTask = action.payload;
    });
    builder.addCase(manageUpdateTask.rejected, (state, action) => {
      state.isUpdateTaskLoading = false;
      state.isUpdateTaskSuccess = false;
      state.isUpdateTaskFailed = true;
      state.error = action.error.message;
    });
    // user completed tasks
    builder.addCase(completedTasks.pending, (state, action) => {
      state.isUserCompletedTaskLoading = true;
    });
    builder.addCase(completedTasks.fulfilled, (state, action) => {
      state.isUserCompletedTaskLoading = false;
      state.isUserCompletedTaskSuccess = true;
      state.userCompletedTask = action.payload;
    });
    builder.addCase(completedTasks.rejected, (state, action) => {
      state.isUserCompletedTaskLoading = false;
      state.isUserCompletedTaskSuccess = false;
      state.isUserCompletedTaskFailed = true;
      state.error = action.error.message;
    });
  },
});
export default updateTaskSlicer.reducer;
