import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  InputBase,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { FaDiscord } from "react-icons/fa";
import TwitterIcon from "@mui/icons-material/Twitter";
import LanguageIcon from "@mui/icons-material/Language";
import InstagramIcon from "@mui/icons-material/Instagram";
import ReportIcon from "@mui/icons-material/Report";
import FacebookIcon from "@mui/icons-material/Facebook";
import Button from "../units/Button";
import { updateRewards } from "../../Features/rewardsInfo/rewardsInfo.action";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { overViewStates as getOverViewStates } from "../../Features/overveiw/overview.action";

const RewardManagment = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState();

  const {
    rewardsInfo,
    isRewardsLoading,
    isUpdateRewardsLoading,
    isUpdateRewardsSuccess,
    isUpdateRewardsFailed,
  } = useSelector((state) => state.rewardsInfoSlicer);

  const handleClick = (rewards_id) => {
    let payload = {
      id: rewards_id,
      coins: value,
    };

    dispatch(updateRewards(payload));
  };
  useEffect(() => {
    dispatch(getOverViewStates());
  }, [dispatch]);

  return (
    <>
      {isUpdateRewardsSuccess && (
        <Box
          sx={{
            position: "absolute",
            right: "30%",
            background: "green",
            borderRadius: "5px",
          }}
        >
          <Alert
            variant="soft"
            color="success"
            startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
          >
            Reward updated.
          </Alert>
        </Box>
      )}
      {isUpdateRewardsFailed && (
        <Box
          sx={{
            position: "absolute",
            right: "30%",
            borderRadius: "5px",
          }}
        >
          <Alert variant="soft" color="warning" startDecorator={<ReportIcon />}>
            Somehow failed to change coins
          </Alert>
        </Box>
      )}
      <Box className={classes.container}>
        <>
          {isRewardsLoading ? (
            <CircularProgress color="secondary" />
          ) : (
            <form className={classes.form}>
              {rewardsInfo &&
                rewardsInfo?.map(
                  ({ rewards_id, coins, social_link, social_name }) => {
                    return (
                      <Box className={classes.inpufieldContiner}>
                        <Box className={classes.labelContainer}>
                          <label className={classes.label}>
                            {social_name === "youtube" ? (
                              <YouTubeIcon />
                            ) : social_name === "telegram" ? (
                              <TelegramIcon />
                            ) : social_name === "twitter" ? (
                              <TwitterIcon />
                            ) : social_name === "website" ? (
                              <LanguageIcon />
                            ) : social_name === "instagram" ? (
                              <InstagramIcon />
                            ) : social_name === "facebook" ? (
                              <FacebookIcon />
                            ) : (
                              social_name === "discord" && <FaDiscord />
                            )}
                          </label>
                          <Typography sx={{ color: "white" }}>
                            The Current Rewards on{" "}
                            <span style={{ textTransform: "capitalize" }}>
                              {social_name}
                            </span>
                          </Typography>
                        </Box>
                        <Box className={classes.inputContainer}>
                          <InputBase
                            name={social_name}
                            onChange={(e) => setValue(e.target.value)}
                            placeholder={coins}
                            className={classes.input}
                          />
                          {isUpdateRewardsLoading ? (
                            <CircularProgress
                              color="error"
                              size="small"
                              className={classes.progres}
                            />
                          ) : (
                            <Button
                              onClick={() => handleClick(rewards_id)}
                              className={classes.submitButton}
                            >
                              Change
                            </Button>
                          )}
                        </Box>
                      </Box>
                    );
                  }
                )}
            </form>
          )}
        </>
      </Box>
    </>
  );
};
export default RewardManagment;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
  },
  form: {
    padding: "2rem 3rem",
    width: "50% !important",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    borderRadius: "5px",
    background: theme.palette.background.text,
  },
  inpufieldContiner: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    outline: "none",
    alignItems: "center",
    flexDirection: "row",
    gap: "10px",
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    letterSpacing: "3px",
    width: "35px",
  },
  progres: {
    color: theme.palette.text.primary,
  },
  discord: {
    color: `${theme.palette.text.primary} !important`,
  },
  input: {
    bgcolor: "#d8d8d8",
    color: "black",
    padding: "5px 10px",
    borderRadius: "5px",
    fontWeight: "bold",
    width: "100%",
    border: `1px solid ${theme.palette.text.primary} !important`,
    outline: "none",
  },
  submitButton: {
    backgroundColor: `${theme.palette.btn} !important`,
    // color: "black !important",
    color: `${theme.palette.text.primary} !important`,
    textTransform: "capitalize !important",
    fontWeight: "bold !important",
    border: `1px solid ${theme.palette.text.primary} !important`,
    fontSize: "16px  !important",
    width: "fit-content !important",
    padding: "5px 25px !important",
    alignSelf: "center !important",
    letterSpacing: "1px",
    transition: "transform 500ms ease-linear !important",
    "&:hover": {
      // backgroundColor: "#efa907 !important",

      transform: "scale(1.05) !important",
    },
  },
  labelContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  inputContainer: {
    display: "flex",
    gap: 5,
  },
  typos: {
    color: `${theme.palette.text.primary} !important`,
  },
}));
