import { makeStyles } from "@mui/styles";
import { Box, Button, InputBase } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { FaDiscord } from "react-icons/fa";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LanguageIcon from "@mui/icons-material/Language";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Toaster } from "react-hot-toast";

import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
import { updatePartnerLinks } from "../../Features/partnerManagmet/partnerMng.action";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { overViewStates as getOverViewStates } from "../../Features/overveiw/overview.action";
const PartnerManagement = () => {
  const classes = useStyles();
  const { rewardsInfo } = useSelector((state) => state.rewardsInfoSlicer);
  const { updatePartners, isRewardsSuccess } = useSelector(
    (state) => state.updateLinks
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOverViewStates());
  }, [dispatch]);
  const [initialValues, setInitialValues] = useState({
    youtube: "",
    discord: "",
    telegram: "",
    twitter: "",
    instagram: "",
    facebook: "",
    website: "",
  });

  useEffect(() => {
    if (rewardsInfo) {
      const values = rewardsInfo.reduce((acc, { social_name, social_link }) => {
        acc[social_name] = social_link || "";
        return acc;
      }, {});
      setInitialValues(values);
    }
  }, [rewardsInfo]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true, // Allows formik to update initialValues when they change
    onSubmit: (values) => {
      const payload = Object.keys(values)
        .filter((key) => values[key].trim() !== "") // Filter out empty links
        .map((key, index) => ({
          id: index + 1,
          social_link: values[key],
        }));
      

      dispatch(updatePartnerLinks(payload));
      isRewardsSuccess && toast.success("change Success full");
    },
  });

  return (
    <Box className={classes.container}>
      <Toaster position="bottom-right" reverseOrder={false} />

      <form onSubmit={formik.handleSubmit} className={classes.form}>
        {rewardsInfo &&
          rewardsInfo.map(({ social_name }) => (
            <Box className={classes.inputFieldContainer} key={social_name}>
              <label className={classes.label}>
                {social_name === "youtube" && <YouTubeIcon />}
                {social_name === "telegram" && <TelegramIcon />}
                {social_name === "twitter" && <TwitterIcon />}
                {social_name === "instagram" && <InstagramIcon />}
                {social_name === "facebook" && <FacebookIcon />}
                {social_name === "discord" && (
                  <FaDiscord style={{ fontSize: "25px" }} />
                )}
                {social_name === "website" && <LanguageIcon />}
              </label>
              <InputBase
                name={social_name}
                onChange={formik.handleChange}
                placeholder={`${social_name} link..`}
                className={classes.input}
                value={formik.values[social_name] || ""}
              />
            </Box>
          ))}
        <Button type="submit" className={classes.submitButton}>
          Publish
        </Button>
      </form>
    </Box>
  );
};

export default PartnerManagement;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
  },
  form: {
    padding: "2rem 3rem",
    width: "50% !important",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    borderRadius: "5px",
    background: theme.palette.background.text,
  },
  inputFieldContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    letterSpacing: "3px",
  },
  input: {
    bgcolor: "#d8d8d8",
    color: "black",
    padding: "5px 10px",
    borderRadius: "5px",
    fontWeight: "bold",
    width: "100%",
    border: `1px solid ${theme.palette.text.primary} !important`,
    outline: "none",
  },
  submitButton: {
    color: `${theme.palette.text.primary} !important`,
    fontWeight: "bold !important",
    border: `1px solid ${theme.palette.text.primary} !important`,
    fontSize: "16px  !important",
    width: "fit-content !important",
    padding: "5px 40px !important",
    alignSelf: "center !important",
    letterSpacing: "1px",
    transition: "transform 500ms ease-linear !important",
    "&:hover": {
      transform: "scale(1.05) !important",
    },
  },
}));
