import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkSessionApi, loginApi, verifyOtpApi } from "./api";
/////////////////////////////////////////////////
export const adminLogin = createAsyncThunk(
  "auth/login",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await loginApi(payload);

      return res.data;
    } catch (err) {
      // Use rejectWithValue to pass the error payload to the reducer
      return rejectWithValue(err.response ? err.response.data : err.message);
    }
  }
);
/////////////////////////////////////////////////
export const verifyOtp = createAsyncThunk(
  "auth/verifyOTP",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await verifyOtpApi(payload);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response ? err.response.data : err.message);
    }
  }
);

///////////////////////////////////////////////////
export const checkSession = createAsyncThunk(
  "auth/checkSession",
  async (payload) => {
    try {
      const token = localStorage.getItem("authToken");
      const res = await checkSessionApi(token);
      return res.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);
