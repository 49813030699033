import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  loginFirstBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  formStyle: {
    backgroundColor: "#252627",
    padding: "4rem 3rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    width: "30%",
    borderRadius: "5px",
  },
  logoBox: {
    display: "flex",
    justifyContent: "center",
  },
  typoContent: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  typography: {
    color: "#e5e5e5",
    fontWeight: "bold",
    letterSpacing: "3px",
  },
  inputStyle: {
    width: "100%",
    color: "white",
    padding: "10px 15px",
    borderRadius: "5px",
    fontWeight: "bold",
    backgroundColor: "#6B6767 !important",
    fontSize: "18px !important",
  },
  buttonStyle: {
    backgroundColor: "#6B6767 !important",
    "&:hover": {
      color: "black  !important",
      backgroundColor: "#e5e5e5 !important",
    },
    color: `${theme.palette.text.primary} !important`,
    fontWeight: "bold !important",
    fontSize: "16px !important",
  },
  NavLink: {
    textDecoration: "none !important",
  },
  errorText: {
    color: "red !important",
    fontSize: "14px !important",
  },
}));
