import { createSlice } from "@reduxjs/toolkit";
import { ViewTasks } from "./viewTask.action";

const initialState = {
  isManageTaskLoading: false,
  isManageTaskFailed: false,
  isManageTaskSuccess: false,
  tasks: [],
  error: {},
};
const taskViews = createSlice({
  name: "rewards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ViewTasks.pending, (state) => {
      state.isManageTaskLoading = true;
    });
    builder.addCase(ViewTasks.fulfilled, (state, action) => {
      state.isManageTaskLoading = false;
      state.isManageTaskSuccess = true;
      state.tasks = action.payload;
    });
    builder.addCase(ViewTasks.rejected, (state, action) => {
      state.isManageTaskLoading = false;
      state.isManageTaskSuccess = false;
      state.isManageTaskFailed = true;
      state.error = action.payload;
    });
  },
});
export default taskViews.reducer;
