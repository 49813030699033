import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHostDataApi } from "./api";

export const getHostData = createAsyncThunk("host/getData", async payload => {
  try {
    const res = await getHostDataApi(payload);
    return res;
  } catch (error) {
    throw error;
  }
});
