import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateMngTask, userCompletedTasks } from './api';

export const manageUpdateTask = createAsyncThunk(
  'task/updateManageTask',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await updateMngTask(payload);
      return res.data;
    } catch (err) {
      throw err.response.data.message;
    }
  }
);

// fetch completed tasks actions
export const completedTasks = createAsyncThunk(
  'task/allCompletedTasks',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await userCompletedTasks(payload);
      return res.data;
    } catch (err) {
      throw err;
    }
  }
);
