import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "#6B6767",
    outline: "none",
    borderRadius: "10px",
  },
}));
