import { Box } from "@mui/material";
import Header from "../header";

const Wrapper = ({ children, pageName }) => {
  return (
    <Box sx={{ padding: 3 }}>
      <Header heading={pageName} />
      <Box sx={{ paddingTop: 3 }}>{children}</Box>
    </Box>
  );
};

export default Wrapper;
