import { createAsyncThunk } from "@reduxjs/toolkit";
import { viewTask } from "./api";

export const ViewTasks = createAsyncThunk(
  "task/manageTask",
  async (payload) => {
    try {
      const res = await viewTask(payload);
      return res.data;
    } catch (err) {
      throw err.response.message;
    }
  }
);
